@import "../../style/variables";

.container {
    flex-shrink: 0;
}

.icon {
    cursor: pointer;
    color: $color-secondary;
    transition: color .3s ease;

    &:hover {
        color: $color-text;
    }
}
.title {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    margin-bottom: 6px;
  }