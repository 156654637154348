@import "../../../style/variables";

.header {
    font: normal 14px/18px "Helvetica";
    background-color: $color-secondary;
    color: $color-white;
}

.icon {
    cursor: pointer;
    color: $color-secondary;
    transition: color .3s ease;

    &:hover {
        color: $color-text;
    }
}