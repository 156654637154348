@import "../../../style/variables";

.icon {
    cursor: pointer;
    color: $color-text;
    transition: color .3s ease;

    &:hover {
        color: $color-primary;
    }
}

.arrowIcon {
    width: 15px!important;
    height: 15px!important;
    padding-bottom: 2px;
  }
