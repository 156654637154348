/* Provide sufficient contrast against white background */
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.cursor-pointer {
  cursor: pointer !important;
}

.common-text {
  font: normal 13px/15px Arial, Helvetica, sans-serif;
  letter-spacing: 0;
}

.snack-container {
  padding-right: 20px;
}

.sub-title {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  margin-bottom: 6px;
}
