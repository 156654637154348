@import "./../../style/variables";
.container {
    background-color: $color-form-bg;
    max-width: 700px !important;
}

.info {
    font: 13px/15px Arial, Helvetica, sans-serif;
}

.subTitle {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
    margin-bottom: 6px;
}

/* .col-centered {
  float: none;
  margin: 0 auto;
}

.sectionWBorder{
  border:1px solid gray;
  border-radius: 5px;
  padding:15px;
}

.sectionBorder{
  border:1px solid gray;
  border-radius: 5px;
  padding:15px;
}

.cmc-job-status{
  font-size: 13px;
  line-height:15px ;
  font-weight: normal;
  font-family: Arial, Helvetica, sans-serif;
}

.cmc-job-status-bold{
  letter-spacing: 0px;
  font-size: 13px;
  line-height:15px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
}

.show-detail-text{
  letter-spacing: 0px;
  font-size: 16px;
  line-height:20px;
  font-weight: normal;
  font-family: Arial, Helvetica, sans-serif;
}

.custom-input {
  border-color: grey;
}

.custom-input:focus {
  border-color: #A72228;
}
.MuiTableCell-root {
  padding: 0px 0px !important;
}

.MuiTypography-root-MuiDialogTitle-root {
  padding: 0px !important;
}

.table-cell-picker{
  padding:0 !important;
  input{
      padding-top: 0 !important;
      padding-bottom: 0 !important;
  }
} */