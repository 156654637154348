.table_cell{
  background-color: "rgb(99, 93, 99)";
  border: "1px solid black";
  color: "#fff";
  letter-spacing: '0px';
  font-size: '12px';
  line-height:'18px';
  font-weight: 'normal';
  font-family: 'Arial, Helvetica, sans-serif';
  padding-left: '10px !important';
  /* '& .MuiTableCell-root':{
      paddingLeft: '10px !important'
  }, */
}
.MuiTableCell-root {
  padding: 0px 0px !important;
}
.MuiTypography-root-MuiDialogTitle-root {
padding: 0px !important;
}
.table-cell-picker{
padding:0 !important;
input{
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
}
.title {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  margin-bottom: 6px;
}