@import "./../../style/variables";

.container {
    background-color: $color-form-bg;
    max-width: 700px !important;
}

.info {
    font: 13px/15px Arial, Helvetica, sans-serif;
}

.subTitle {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
    margin-bottom: 6px;
}
.title {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    margin-bottom: 6px;
}