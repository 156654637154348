// Theme colors
$color-primary: #A72228;
$color-secondary: #635D63;

$color-text: #2A2A2A;

$color-gray: #B0ADB0;
$color-gray-light: #E3E0DF;
$color-white: #fff;
$color-black: #000;
$color-form-bg: #e3e0df;

$nav-border: #E8E7E8;
