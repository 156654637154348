.swatch-wrapper {
    background-color: #fff;
    max-width: 160px;
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    padding: 10px;
}

.swatch {
    display: block;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: 2px solid transparent;
    cursor: pointer;
}

.swatch.blank {
    border-color: #ddd;
    background: linear-gradient(to top left, #fff calc(50% - 1px), #f00 , #fff calc(50% + 1px) )
}

.swatch.highlight {
    border-color: #000;
}