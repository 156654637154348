@import "../../style/variables";

.header {
    font: normal 14px/18px "Helvetica", sans-serif;
    background-color: $color-secondary;
    color: $color-white;
}

.icon {
    cursor: pointer;
    color: $color-secondary;
    transition: color .3s ease;

    &:hover {
        color: $color-text;
    }
}

.horizontalLabel {
    width: 70px;
    font: normal 13px/15px "Helvetica", sans-serif;
    text-align: right;
    padding-right: 6px;
    flex-shrink: 0;

}
