.MuiTableCell-root {
    padding: 0px 0px !important;
}
.MuiTypography-root-MuiDialogTitle-root {
  padding: 0px !important;
}
.table-cell-picker{
  padding:0 !important;
  input{
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.clsAdvReceivingTbl .MuiInputBase-input,
.clsDirectTbl .MuiInputBase-input {
    padding-left: 0px;
}

.clsAdvReceivingTbl .cellheading, .clsDirectTbl .cellheading {
    border-bottom-width: 1px !important;
    border-bottom-style: solid !important;
    border-bottom-color: #fff !important;
    background-color: #635D63;
    height: 40px;
    color: #fff;
}

.clsAdvReceivingTbl .cellcontent, .clsDirectTbl .cellcontent {
    border-bottom-width: 1px !important;
    border-bottom-style: solid !important;
    border-bottom-color: #635D63 !important;
    height: 40px;
}


@media (min-width: 768px) {
    .clsadvRecevingRightCol, .clsDirectRightCol {
        padding-top: 40px;
        padding-left: 0px;
        padding-right: 0px;
    }
}
