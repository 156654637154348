@import "./../../style/variables";

.tabWrapper {
    box-shadow: inset 0 -4px 0 $nav-border;
}

.logo {
    width: 120px;
    height: auto;
}

.toggle {
    background-color: $color-gray-light;
}

@media (min-width: 768px) {
    .logo {
        width: auto !important;
    }
}