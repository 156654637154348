@import "../../style/variables";

.icon {
    cursor: pointer;
    color: $color-text;
    transition: color .3s ease;

    &:hover {
        color: $color-primary;
    }
}
